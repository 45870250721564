import React from 'react'
import Logo from '../../assets/logo.svg'
import Img from 'gatsby-image'

const Image = props => {
  const { fluid, alt } = props

  if (fluid == null || fluid.src == null) {
    return (<div className="squareImage"><img src={Logo} alt={alt} /></div>)
  } else {
    return <Img fluid={fluid} alt={alt} />
  }
}

export default Image
