import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import './Header.scss'
import {
  Button,
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import logo from '../../assets/logo.svg'

const Header = () => {
  const data = useStaticQuery(graphql`
    query DailyWorkoutsQuery {
      allWordpressWpDailyworkouts(limit: 1, sort: {order: DESC, fields: acf___workout_day}) {
        edges {
          node {
            acf {
              workout_day
            }
          }
        }
      }
      allWordpressWpOfkWorkout(limit: 1) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)

  const navLink = () => (
    <Link className="navbar-brand" to="/">
      <img src={logo} alt="OCR Academy" /> OCR Academy
    </Link>
  )

  return (
    <div className="Header">
      <Navbar color="dark" dark expand="md">
        <NavbarBrand tag={navLink} />
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>OCR Academy</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/team">Meet the Team</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/news">What's being said about OCR</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>What We Do</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/rules">OCRA Rules</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/obstacles">Our Obstacles</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/classes">Our Classes</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/schedule">Schedule</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/pricing">Pricing</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>OCR Kids</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/camp">Camps/PA Days</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/obstaclefitkids">Obstacle Fit Kids</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/ofkathome">OFK at Home</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to={`/ofkworkout/${data.allWordpressWpOfkWorkout.edges[0].node.slug}`}>OFK Workout of the Day</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/afterschool">After School Program</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/bookaparty">Book a Party</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <Link className="nav-link" to="/contact">
                Contact Us
              </Link>
            </NavItem>
          </Nav>
          <Nav className="ml-auto">
            <Link to={`/dailyworkout/${data.allWordpressWpDailyworkouts.edges[0].node.acf.workout_day}`}>
              <Button color="success">Workout of the Day</Button>
            </Link>
            <Link to="/waiver">
              <Button color="danger">Sign the waiver</Button>
            </Link>
          </Nav>
        </Collapse>
      </Navbar>
      <div className="pandemic">
        <p><Link to="/covid-19">For our new policies and procedures please see our Pandemic guidelines for our new normal.</Link></p>
      </div>
    </div>
  )
}

export default Header
