import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../../index.scss'
import './Layout.scss'
import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <Helmet
      title="OCR Academy Ottawa"
      meta={[
        { name: 'description', content: "OCR Academy is Ottawa's first and only obstacle course training and fitness centre. We build fit kids and healthy adults. ALL ages and ALL fitness levels welcome." },
        { name: 'keywords', content: 'Ottawa fitness, training, kids fitness, health centre, healthy, kids classes, after school, kids camps' },
      ]}
    >
        <html lang="en" />
    </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
