import React from 'react'
import { Link } from 'gatsby'
import './Footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faYoutube,
  faFacebookSquare
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container">
        <div className="footerLeft">
          <Link to="/blog">Blog</Link> |
          <Link to="/termsofuse">Terms of Use</Link> |<Link to="/faq">FAQ</Link>
        </div>
        <div className="footerRight">
          <div className="contact">
            <FontAwesomeIcon icon={faHome} />
            <div><a href="https://g.page/OCRAcademy?share" target="_blank" rel="noopener noreferrer">1140 Morrison Drive, Unit 106 B, Ottawa ON</a></div>
            </div>
            <div className="contact">
            <FontAwesomeIcon icon={faPhone} />
            <div><a href="tel:6137260909">613.726.0909</a></div>
            </div>
            <div className="contact">
            <FontAwesomeIcon icon={faEnvelope} />
            <div><a href="mailto:info@painistemporary.net">info@painistemporary.net</a></div>
          </div>
          <div className="social">
            <a
              href="https://www.instagram.com/ocracademy/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="OCR Academy Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCIXnG4iFfwQWhDnjnQYsACQ"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="OCR Academy Youtube"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="https://www.facebook.com/obstaclecourseracingacademy/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="OCR Academy Facebook"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
