import React from 'react'
import './HeroBanner.scss'
import Image from '../Image'

const HeroBanner = props => {
  const { image, title, caption, actionURL, actionText, newWindow } = props

  return (
    <div className="heroBanner">
      <Image fluid={image} alt={title} />
      <div className="heroBannerInner row text-center text-md-left">
        <div className="heroInfo col-sm-12 col-md-6">
          <h1 className="heroTitle text-center text-md-left">{title}</h1>
          <div className="heroCaption text-center text-md-left">{caption}</div>
        </div>
        <div className="heroAction col-sm-12 col-md-6 text-center text-md-right">
          {actionURL && actionURL !== '' ? (
            <a href={actionURL} target={newWindow === true ? "_blank" : "_self"}>
              <div className="heroActionButton text-center text-md-left">{actionText}</div>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
